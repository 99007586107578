import React from 'react';

export const IntroBlock = () => {
    return (
        <div className="block section blue">
            <div className="finesse">
                <h2>THIS IS CLOUDPAY</h2>
                <p className="lime-text">Finally, a chrome plugin that pays you for your data.</p>
            </div>
            <div className="next-section">
                <img className="down-arrow" src="./images/down arrow.png"/>
                <div className="section-next-text">SEE HOW IT WORKS</div>
            </div>
            <img src="./images/clouds.png" className="clouds"/>
        </div>

    );
}

export const SecondBlock = () => {
    return (
        <div className="block section faded">
            <div className="finesse">
                <h2 style={{color: "#ff266f"}}>SO HOW DOES CLOUDPAY WORK?</h2>
                <p className="goldmine">For years, companies have paid for your data. Now, they’ll pay you.</p>
            </div>
            <div className="explantion">
                <p>
                With the CloudPay plug-in, you gain access to a data marketplace where we 
analyze your data and offer it to interested vendors. Then, we serve you products
you’ll be interested in. For every product you buy, you immediately get cashback.
                </p>
                <p>
                Soon, we’ll also offer CloudPay Wallet, which will give you the additional option to store money in the wallet and watch it grow as we invest it on your behalf.
                </p>
            </div>
            <div className="next-section">
                <img className="down-arrow" src="./images/down arrow blue.png"/>
                <div className="section-next-text blue-text">SEE THE PERKS</div>
            </div>
        </div>

    );
}

export const ThirdBlock = () => {
    return (
        <div className="block block-small section">
            <div className="finesse">
                <h2 style={{color: "#ff266f"}}>Plug in. Cash Out</h2>
                <p className="goldmine"> Contact us at <br/> <a target="_blank" href="mailto:info@getcloudpay.com?Subject=Interest">info@getcloudpay.com</a> or fill out the form below to stay updated. Or, let us know if you want to work with us as a vendor.</p>
                <div className="explantion">
                        <p className="small">Chrome plugin coming soon</p>
                </div>
            </div>
        </div>

    );
}