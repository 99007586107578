import React, {useState} from 'react';
import {HomeLayout} from '../components/layout';
import { Link } from 'react-router-dom';
import Switch from '../components/Switch';
import {baseUrl} from '../shared';
import axios from 'axios';


const SignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isVendor, setIsVendor] = useState(false);

    return (
        <HomeLayout>
            <div className="block section blue">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (email.length > 3 && password.length > 5) {
                            try{


                            }
                             catch(error){  
                                console.log(error);
                                alert('sorry there was an error:', error);
                             }
                        }
                        else {
                            alert("email must but valid and password length but be longer than 5")
                        }
                    }}
                    className="signup">
                        <Switch
                            isOn={isVendor}
                            style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}
                            onColor="#fe266f"
                            handleToggle={() => setIsVendor(!isVendor)}
                        />
                    <div className="vendor-shopper-label">{isVendor? 'vendor': 'shopper'}</div>

                    <h1 className="sign-title">SIGN UP</h1>
                    <label for="name" className="label">name</label>
                    <input 
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        className="cloudpay-input" 
                        placeholder="name"  
                        name="name" 
                        text="text"/> 
                    <label for="email"  className="label">email</label>
                    <input 
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        className="cloudpay-input" 
                        placeholder="email"  
                        name="email" 
                        type="text"/>
                    <label for="password"  className="label">password</label>
                    <input 
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        className="cloudpay-input" 
                        placeholder="password"  
                        name="password" 
                        type="password"/> 
                    <button className="cloudpay-button">SIGN UP</button>
                    <Link to="/signin" className="sign-switch"> Already have an account? Sign In.</Link>

                </form>

                <img src="./images/clouds.png" className="clouds"/>
            </div>
        </HomeLayout>

    )
}

export default SignUp;