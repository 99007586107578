import React from 'react';
import Header from './Header';
import Footer from './Footer';

export const HomeLayout = ({children}) => {
    return (
        <div className="App">
            <Header/>
            {children}
            <Footer/>
        </div>

    )
}

export const InLayout = ({children, name}) => {
    return (
        <div className="App">
            <Header signedIn name={name}/>
            {children}
        </div>

    )
}

