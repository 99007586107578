import React from 'react';
import { IntroBlock, SecondBlock, ThirdBlock } from '../components/IntroBlocks';
import { AD1, AD2, Signup} from '../components/AdBlocks';

import {HomeLayout} from '../components/layout';

const Home = () => {
    return (
      <HomeLayout>
        <IntroBlock />
        <SecondBlock />
        <AD1 />
        <AD2 />
        <ThirdBlock />
        <div className="signup-block" style={{ minHeight: 380, padding: 30}}>
          <Signup style={{width: '95%', padding: 5}} />
        </div>
      </HomeLayout>
    );
}

export default Home;