import React, { useRef, useEffect } from 'react';
import * as typeformEmbed from "@typeform/embed";

export const AD1 = () => {
    return (
        <div class="info-slide">
            <div className="ad-info">
                <h1 className="red-text">
                Get paid  to shop @ your favorite  brands.
                </h1>
                <p>
                You shop like normal. We collect data like normal. But we pay you for it. And, we offer you cashback on products that you’ll actually want. 
                </p>
            </div>
            <div className="ad-image">
                 <img src="./images/ken3.png" />
            </div>
        </div>
    );
}


export const AD2 = () => {
    return (
        <div class="info-slide">
           
            <div className="ad-image">
                <img src="./images/shop.png" />
            </div>
            <div className="ad-info red red-info">
                <h1>
                And for  brands, we advertise  for you, free.
                </h1>
                <p>
                Our unique, centralized hub analyzes customer data to accurately identify potential customers in real-time.
                </p>
                <p>
                    The moment potential buyers show interest in your category or product, we suggest your product with an enticing cash-back offer (generated based on commission from selling your product).  

                </p>
              
            </div>
        </div>
    );
}

export const Signup = () => {
    const typeformRef = useRef(null);
  
    useEffect(() => {
        typeformEmbed.makeWidget(typeformRef.current, 'https://thunderpowell.typeform.com/to/b0G9lsJ1', {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
        });
    }, [typeformRef]);
    return (
        <div ref={typeformRef} className="ReactTypeformEmbed" id="#signup" style={{ height: 300, width: '100%', padding: 20}}></div>
    );
}