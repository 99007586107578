import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import SignUp from './pages/sign-up';
import SignIn from './pages/sign-in';
import Dashboard from './pages/dashboard';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";




const App = () => (
  <Router>
    <Switch>
      <Route path="/" exact>
        <Home/>
      </Route>
      <Route path="/signup" >
        <SignUp/>
      </Route>
      <Route path="/signin" >
        <SignIn/>
      </Route>
      <Route path="/dashboard" >
        <Dashboard/>
      </Route>
    </Switch>
  </Router>
);



export default App;
