import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({signedIn, name, signOut}) => {
    const regular = (
        <>
            <Link to="/">
                
                <img src={'./images/cloudpay logo new.png'} />
            </Link>
            <a className="header-cta" href="https://thunderpowell.typeform.com/to/b0G9lsJ1" target="_blank">SIGN UP</a>
        </>
    )
    return (
    <header className="header">
        {
            signedIn?
            
            (
                <>
                 <Link to="/">
                
                    <img src={'./images/cloudpay logo blue.png'} />
                </Link>

                <div className="signed-in-section">
                   <span className="welcome">Welcome, <span className="header-name">{name} </span></span> 
                   <button className="sign-out">SIGN OUT</button>
                </div>
                </>
            )
            :
            regular
        }
    </header>)
}

export default Header;