import React from 'react';
import '../styles/Switch.css'

const Switch = ({ isOn, handleToggle, onColor, style, text }) => {
    const color = onColor? onColor : '#EF476F';
    return (
      <div style={style || {display: 'flex', alignItems: 'center'}}>
          <span className="label-text">Vendor</span>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={`react-switch-new`}
          type="checkbox"
        />
        <label
          style={{ background: isOn && color }}
          className="react-switch-label"
          htmlFor={`react-switch-new`}
        >
          <span className={`react-switch-button`} />
        </label>
      </div>
    );
  };

export default Switch;