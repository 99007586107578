import React, {useState} from 'react';
import {HomeLayout} from '../components/layout';
import Switch from '../components/Switch';
import { Link } from 'react-router-dom';



const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isVendor, setIsVendor] = useState(false);

    return (
        <HomeLayout>
            <div className="block section blue">
                <form className="signup signin" style={ isVendor? {backgroundColor: '#f2f7ff'} : {}}>
                        <Switch
                            isOn={isVendor}
                            style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}
                            onColor="#fe266f"
                            handleToggle={() => setIsVendor(!isVendor)}
                        />
                        <div className="vendor-shopper-label">{isVendor? 'vendor': 'shopper'}</div>
                        <h1 className="sign-title">SIGN IN</h1>
                   
                    <label for="email" className="label">email</label>
                    <input 
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        className="cloudpay-input" 
                        placeholder="email"  
                        name="email" 
                        type="text"/>
                    <label for="password" className="label">password</label>
                    <input 
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        className="cloudpay-input" 
                        placeholder="password"  
                        name="password" 
                        type="password"/> 
                    <button className="cloudpay-button">SIGN IN</button>
                    <Link to="/signup" className="sign-switch"> Don’t have an account? Sign Up</Link>

                </form>

                <img src="./images/clouds.png" className="clouds"/>
            </div>
        </HomeLayout>

    )
}

export default SignIn;