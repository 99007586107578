import React, { useState } from 'react';
import Overlay from '../components/overlay';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'




const Dashboard = () => {
    const moneyMade = 132000.04;
    let outstanding = 1000.30;
    const name = "Marissa Walker";
    const company = "BOSE";
    const rows = [{title: 'CLOUDPAY’S COMMISSION', value: '20%'}, {title: 'NUMBER OF CLOUDPAY CUSTOMERS', value: '500'},
                {title: 'CURRENT PAYMENT OWED', value: '1400.00', red: true}]
    
    const Row = ({title, value, red}) => {
        return (
            <div className="dash-row">
                <div className="row-title">{title}</div>
                <div className={`row-info  ${red? 'red-text': 'blue-text'}`}>{value}</div>
            </div>
        );
    };
    
    const purchases = [{name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] }, 
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },
    {name: 'Omari Powell', date: '4/20/20', cost: 1500.00, email: 'ojp3@cornell.edu', purchases: [{name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}, {name: 'stuff', price: '130.00'}] },];

    const Purchase = ({name, date, email, purchases, cost}) => {
        const [show, setShow] = useState(false);

        return (
        <div className="dash-purcahse">
            <div style={{display: 'flex'}}>
                <div className="purchase-buyer">
                    {name}
                </div>
                <div className="purchase-date">
                    {date}
                </div>
            </div>
            <div className="buyer-email">
                {email}
            </div>
            <div className="purchase-list">
                {show? 

                    (<>
                        {
                        purchases.map( ({name, price}) => {
                        return (
                            <div className='purchase-item'>
                                <div className="purchase-item-name">
                                    {name}
                                </div>
                                <div className="purchase-item-price">
                                    {price}
                                </div>
                            </div>)
                        })
                    }
                    <div
                        onClick={() => {
                            setShow(false)
                        }}
                        className="see-less">
                        close 
                    </div>
                    </>
                    
                    )
                    :
                    (<div
                        onClick={() => {
                            setShow(true)
                        }}
                        className="see-more">
                        see more 
                    </div>)
            }
            </div>
            <div className="purchase-price">
                {cost}
            </div>

        </div>)
    }
    const leftPanel = (
        <>
            <div className="purchases-title">
                PURCHASES MADE USING CLOUDPAY
            </div>
            <div className="dash-purchases-list">
                { purchases.map( ({name, date, email, purchases, cost}) => {
                    return (
                        <Purchase  
                            name={name}
                            date={date}
                            email={email}
                            purchases={purchases}
                            cost={cost}
                        />
                    )
                })}
            </div>
        </>
    );

    return (<div className="dashboard">
        <div className="purchases-panel">
                {leftPanel}
        </div>
        <div className="dashboard-info">
            <img className="cloudpay-logo-blue" src="/images/cloudpay logo blue.png" />
            <div className="dashboard-data">
                <div className="money-made">
                    <div className="money-amount">$ {moneyMade} </div>
                    <div className="money-sub">total cash Made </div>
                </div>
                <div className="info-rows">
                    {rows.map( item => {
                        const {title, value, red} = item;
                        return (
                            <Row 
                                title={title}
                                value={value}
                                red={red === true}
                            />

                        )
                    })}
                </div>

                <div className="balance-info">
                    <div className="outstanding-balance-text">
                        {outstanding > 0? `You have a current outstanding balance of $${1400.54}`: null }
                    </div>
                    {
                        outstanding > 0 ?
                        (<button className="dashboard-button">PAY BALANCE</button>)
                        : <div className="disabled-button"> NO BALANCE </div>
                    }
                </div>
                <div className="dashboard-bottom">
                    <span className="dashboard-name"> {name} </span> |
                    <span className="dashboard-company"> {company} </span>
                    <faChevronDown className="dashboard-options" />
                </div>

            </div>
        </div>
    </div>);
}

export default Dashboard;